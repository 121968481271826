import { NgModule,ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {Prime_NgModule} from '@root/app/primeNGmodule/primeng.module'
import {LockComponent} from '@components/lock/lock.component'
import {LockErrorConfirmComponent} from '@components/lock-error-confirm/lock-error-confirm.component'
import { AclDirective } from '@directives/acl.directive';
import { VersioningComponent } from '@root/app/components/versioning/versioning.component';
import { LockWrapperComponent } from '@components/lock-wrapper/lock-wrapper.component';
import {TranslationModule,LocalizationModule} from 'angular-l10n'
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    LockComponent,
    AclDirective,
    VersioningComponent,
    LockErrorConfirmComponent,
    LockWrapperComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    Prime_NgModule,
    TranslationModule,
    LocalizationModule,
    NgSelectModule
  ],
  exports:[
    CommonModule,
    FormsModule,
    Prime_NgModule,
    LockComponent,
    
    LockErrorConfirmComponent,
    LockWrapperComponent,
    NgSelectModule,
    TranslationModule,
    LocalizationModule
  ]
})
export class SharedModule { }
