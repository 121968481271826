import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@root/app/app.module';
import { environment } from '@envs/environment';
//import { AppInjector } from './app/service/app-injector.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

//.then((moduleRef) => {AppInjector.setInjector(moduleRef.injector);})