import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { LocaleService, TranslationService, Language, DefaultLocale, Currency, Timezone, L10nLoader, L10N_CONFIG, L10nConfigRef } from 'angular-l10n';
import { environment } from '@envs/environment';
import { AppGlobals } from '@root/app/globals';
import { CustomTranslationProvider } from './service/translate/translate.service';
import { BehaviorSubject } from 'rxjs';
import { AppConfiguration } from './app-configuration.service';
//export var language ="it-It"
export var baseurl: string;
export var api: string;
export var apiLanguage: BehaviorSubject<string> = new BehaviorSubject<string>("");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppGlobals]
})

export class AppComponent implements OnInit {
  title = 'common-angular';
  isLoad = false;
  constructor(private activeRoute: ActivatedRoute, private router: Router, public translation: TranslationService, public locale: LocaleService,
    private location: Location,
    private l10nLoader: L10nLoader,
    @Inject(L10N_CONFIG) private configuration: L10nConfigRef,
    appconfig: AppConfiguration
  ) {
    baseurl = appconfig.apiUrl;
    api = baseurl;
  }
  //resetFilter = ["/home","/customers"]
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
  ngOnInit(): void {
    this.configuration.locale.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let language = (localStorage.getItem('defaultLocale') !== null) ? localStorage.getItem('defaultLocale') : this.configuration.locale.defaultLocale.languageCode + '-' + this.configuration.locale.defaultLocale.countryCode
    apiLanguage.next(language)
    this.l10nLoader.load().finally(() => {
      let domEl = document.getElementById("appLoader");
      domEl.style.display = "none"
      this.isLoad = true
    }
    )
    apiLanguage.subscribe(
      (language) => {
        api = baseurl + language + '/';
      }
    )

  }

}
