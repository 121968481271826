import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth/auth.service'
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { L10nLoader } from 'angular-l10n';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {

  constructor(
    private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private l10nLoader: L10nLoader) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (!this.authService.isLogged()) {
      this.router.navigate(['login'], { queryParams: { 'token': 'invalid' }, skipLocationChange: true })
      return false;
    } else {
      if (this.authService.userMustChangePw() && route.url.toString() != 'password-change') {

        this.router.navigate(['password-change'], {})

      } else {
        return true;
      }

    }
  }
}
