import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MenuItem } from 'primeng/components/common/menuitem';
import { AuthService } from '@root/app/service/auth/auth.service';
import { MenuService } from '@root/app/service/menu.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private menuService: MenuService
  ) {

  }

  items: MenuItem[];
  is_logged: boolean = false;
  ngOnInit() {

    this.menuService.getMenu().subscribe(
      data => {

        this.items =
          data.map(function (a) {
            let item = {
              label: a.mnuDescrTranslated,
              icon: a.mnuIconClass,
              routerLink: a.mnuUrl,
              routerLinkActiveOptions: { exact: true }
            }

            if (a.subMenu) {
              item["items"] = a.subMenu.map(function (b) {

                return {
                  label: b.mnuDescrTranslated,
                  icon: b.mnuIconClass,
                  routerLink: b.mnuUrl,
                  routerLinkActiveOptions: { exact: true }
                }

              })
            }

            return item;
          })

      },
      error => {

      }
    )

  }

  ngAfterViewChecked(): void {
    this.is_logged = this.authService.isLogged();
    this.cd.detectChanges()
  }

}
