import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { api, baseurl } from '@root/app/app.component'
import { Observable, of, throwError } from 'rxjs';
import { JsonPipe } from '@angular/common';

export const aclKeyStorage: string = "acl";

export interface aclInterface {
  entKey: string,
  mode: number
}

@Injectable({
  providedIn: 'root'
})
export class AclService {

  constructor(private http: HttpClient) { }

  public getAcl() {
    return this.http.get(api + 'bkouser/acl/')
  }

  public getAclFromStorage(): Array<aclInterface> {
    return JSON.parse(localStorage.getItem(aclKeyStorage))
  }

  public getPermission(key: string): number {
    //Recupero le ACL dallo store
    let acl = this.getAclFromStorage();
    //Cerco l'elemento all'interno delle acl
    let element = acl.find(x => x.entKey == key);
    return element.mode
  }



  /**
   * Funzione per controllare se l'utente può vedere o meno i record cancellati
   */
  public canViewDeleteRecord() {
    return this.getPermission('UNDELETEOBJECT') === 2 ? true : false;
  }

  /**
   * Funzione per controllare se l'utente può ripristinare o no le versioni dei record
   */
  public canRestoreRecord() {
    return this.getPermission('frmVersionHistory_aspx') === 2 ? true : false;
  }

}

