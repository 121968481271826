import { Component, OnInit, EventEmitter, ChangeDetectorRef, Input, Output } from '@angular/core';
import { AuthService } from '@root/app/service/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationService, Language } from 'angular-l10n';

@Component({
  selector: 'app-resetPassword',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  cardMode: string;
  @Output() cardModeChange: EventEmitter<string> = new EventEmitter()
  username: string = "";
  isLoading: boolean = false;
  requestResetFail: boolean = false;
  requestResetSuccess: boolean = false;
  responseMessage: string = "";
  requestReset_Text: string = "";
  requestReset_Description: string = "";
  requestResetBtn_Text: string = "";
  requestResetBtnGotoLogin_Text: string = "";
  requestCancelBtn_Text: string = "";
  login_reset_Text: string = "";
  @Language() lang: string
  constructor(
    private authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private translation: TranslationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams.respawntoken && this.route.snapshot.queryParams.respawntoken !== '') {
      this.resetPassword(this.route.snapshot.queryParams.respawntoken);
      this.cardMode = 'RESETPWD'
    } else {
      this.cardMode = 'REQUESTRESETPWD'
    }
    this.requestReset_Text = this.translation.translate('requestReset_Text');
    this.requestReset_Description = this.translation.translate('requestReset_Description');
    this.requestResetBtn_Text = this.translation.translate('requestResetBtn_Text');
    this.requestResetBtnGotoLogin_Text = this.translation.translate('requestResetBtnGotoLogin_Text');
    this.requestCancelBtn_Text = this.translation.translate('requestCancelBtn_Text');
    this.login_reset_Text = this.translation.translate('login_reset_Text');

  }

  requestReset() {
    this.isLoading = true;

    this.authService.requestResetPassword(this.username).subscribe(
      (data) => {
        this.responseMessage = this.translation.translate('requestReset_mailsent');
        this.requestResetFail = false;
        this.requestResetSuccess = !this.requestResetFail

        this.isLoading = false;

      },
      (error: HttpErrorResponse) => {

        switch (error.status) {
          case 404:
            this.responseMessage = this.translation.translate('requestReset_userNotFound')
            break
          case 500:
            this.responseMessage = this.translation.translate('requestReset_genericError')
            break
        }

        this.requestResetFail = true;
        this.isLoading = false;

        //DEBUG
        //this.request_reset_message = this.translation.translate('request_reset_mailsent') 
        //this.requestResetFail = false;
        //this.requestResetSuccess = true;
      }
    )

  }//requestReset


  resetPassword(guid: string) {
    this.isLoading = true;
    this.authService.resetPassword(guid).subscribe(
      (data) => {
        this.responseMessage = this.translation.translate('resetConfirm_mailsent')
        this.requestResetFail = false;

        this.requestResetSuccess = !this.requestResetFail
        this.isLoading = false;


      },
      (error: HttpErrorResponse) => {

        try {
          this.responseMessage = error.error.error_description || error.error.title
        } catch (error) {
          this.responseMessage = "Impossile rigenerare la password"
        }

        this.requestResetFail = true;
        this.isLoading = false;

      }
    )

  }

  public cancelRestorePwd() {
    this.goToLogin()
    //this.router.navigate(['login'])
  }

  public goToLogin() {
    this.router.navigateByUrl('/login')
  }

}
