import { map, filter, mergeMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized, Event, PRIMARY_OUTLET, Params } from '@angular/router';
import { Language, TranslationService } from 'angular-l10n';
import { AppGlobals } from '@root/app/globals'
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Location } from '@angular/common'
//import { join } from 'path';

@Component({
  selector: 'app-menu-left-template',
  templateUrl: './menu-left-template.component.html',
  styleUrls: ['./menu-left-template.component.scss']
})
export class MenuLeftTemplateComponent implements OnInit {
  @Language() lang: string;

  pageTitle: string = '';
  pageTitleVariant: string = '';
  pageTitleTranslate: string = ''
  subsctiption: Subscription;
  items: MenuItem[] = [{ label: "Home" }];

  public breadcrumbs: IBreadcrumb[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translation: TranslationService,
    private location: Location,
    private _appGlobals: AppGlobals) {
    this.breadcrumbs = [];
    this.subsctiption = this._appGlobals.pageTitle.subscribe(value => {
      this.pageTitleVariant = value
    });

  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(data => {
        this.setTitlePage(this.activatedRoute);
        this.pageTitleVariant = ''
        this.setBreadcrumbs();
      });



    this.translation.translationChanged().subscribe(
      (l) => {
        if (l) {
          this.setTitlePage(this.activatedRoute);
          this.setBreadcrumbs();
        }
      }
    );


  }

  ngOnDestroy() {
    // this.subsctiption.unsubscribe()
    this.pageTitleVariant = "";
  }

  //Imposta 
  private setTitlePage(activatedRoute: ActivatedRoute) {
    //Con una funzione ricorsiva prendo il titolo del figlio attuale visto che nel routing di angular vengono riportati anche gli elementi padre
    // return this.pageTitle =  (activatedRoute.firstChild === null) ? activatedRoute.snapshot.data.title : this.setTitlePage(activatedRoute.firstChild);

    let languageTitle = (activatedRoute.firstChild === null) ? activatedRoute.snapshot.data.title : this.setTitlePage(activatedRoute.firstChild);
    if (languageTitle) {
      this.pageTitleTranslate = this.translation.translate(languageTitle);

    }

    /*
        if (activatedRoute.firstChild === null) {
         return  this.pageTitle = activatedRoute.snapshot.data.title
        }
        this.setVoiceMenu(activatedRoute.firstChild)*/
  }

  private setBreadcrumbs() {
    this.items = [];
    this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);

    // this.pageTitle = this.activatedRoute.snapshot.firstChild.data.title;
    // this.pageTitleVariant = "";

  }


  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_PAGETITLE: string = "title";
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

    let children: ActivatedRoute[] = route.children;

    if (children.length === 0) {

      return breadcrumbs;
    }

    for (let child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB) && !child.snapshot.data.hasOwnProperty(ROUTE_DATA_PAGETITLE)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      let myItem: MenuItem = {};

      if (child.snapshot.routeConfig.data) {
        myItem.label = this.translation.translate(child.snapshot.routeConfig.data[ROUTE_DATA_BREADCRUMB] || child.snapshot.routeConfig.data[ROUTE_DATA_PAGETITLE]);
        //myItem.url = child.snapshot.url.toString().split(',').join('/')
        if (url.length > 0) {
          myItem.routerLink = url + '/' + child.snapshot.url.toString().split(',').join('/')

        } else {
          myItem.routerLink = '/' + child.snapshot.url.toString().split(',').join('/')

        }
        //  myItem.routerLink = this.router;
        // myItem.queryParams = { relativeTo: child }
        if (this.items.length > 0) {
          //Se l'attuale elemento è uguale a quello precedente non lo visualizzo, TOOD customer
          if (myItem.label !== this.items[this.items.length - 1].label) {
            this.items.push(myItem);
          }
        } else {
          this.items.push(myItem)
        }
      }
      return this.getBreadcrumbs(child, myItem.routerLink, breadcrumbs);
    }
  }

}

export function getSnapshot(route: ActivatedRoute) {
  let lastChild = route;
  while (lastChild.firstChild) {
    lastChild = lastChild.firstChild;
  }
  return lastChild;
}

interface IBreadcrumb {
  label?: string;
  shortLabel?: string;
  params?: Params;
  url?: string;
}

