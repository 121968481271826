import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '@root/app/service/auth/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { CustomTranslationProvider } from '@root/app/service/translate/translate.service';
import { LocaleService, L10nLoader, Language, TranslationService, L10N_CONFIG, L10nConfigRef, DefaultLocale } from 'angular-l10n';
import { CMSCONFIG } from '@root/app/globals'
import { Subscription } from 'rxjs';
import { environment } from '@root/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { apiLanguage } from '@app/app.component';
import { Location } from '@angular/common';
import { ResetPasswordComponent } from '@components/login/resetPassword/resetPassword.component'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush //senza questa impostazione le traduzioni effettuate mediante pipe non rilevano il cambiamento dopo che il redirect dovuto alla non validazione del token
})

export class LoginComponent implements OnInit {

  loginInfo = {

    "username": environment.tester.user,
    "password": environment.tester.pwd,
    "language": ""
  }

  login_error_message: string = "error_text_generic"

  @Language() lang: string;
  @DefaultLocale() defaultLocale: string;
  public loginFail: boolean = false;
  tryLogin: boolean = false;
  languages: Array<string> = []
  page_view = false;
  connection_error: boolean = false;
  connection_error_message: string= "";
  lblUsername: string = "";
  lblPassword: string = "";
  login_Text: string = "";
  hrDescription: string = "";
  lblLanguage: string = "";
  enter_Text: string = "";
  respawn_label: string = "";
  subscriptions: Subscription[] = []
  constructor(
    private authService: AuthService, private router: Router, private locale: LocaleService, private translateService: CustomTranslationProvider,
    private l10nLoader: L10nLoader, private translation: TranslationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private location: Location,
    @Inject(L10N_CONFIG) private configuration: L10nConfigRef,

  ) { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  ngOnInit() {
    let domEl = document.getElementById("appLoader");
    domEl.style.display = "block"

    //Chiamata per ottenere le lingue disponibili
    this.subscriptions.push(this.translateService.getLanguages()
      .pipe(
        finalize(() => this.page_view = true)
      )
      .subscribe(
        data => {
          //Estraggo la lingua salvata nello store, in questo modo ho la lingua di preferenza dell'utente, se ho lo store vuoto imposto quella di default del server
          this.loginInfo.language = (localStorage.getItem('defaultLocale') != null ? localStorage.getItem('defaultLocale') : data.find(x => x['langIsDefault'] === true)['langCode']);
          //Salvo i dati e abilito la vista della pagina
          this.languages = data;
          //Se il token non è più valido ricarico il modulo in modo da ricaricare le traduzione della pagina di login
          if (this.route.snapshot.queryParams.token === 'invalid') {
            this.setLocalInfo(this.loginInfo.language)
          }

          let domEl = document.getElementById("appLoader");
          domEl.style.display = "none";
        },
        (error: HttpErrorResponse) => {
          if(error.status == 0 || error.status == 503){
            this.connection_error = true;
            this.connection_error_message = error.statusText
          }
        }
      )
    )

    this.translation.translationChanged().subscribe(
      () => {
        this.lblUsername = this.translation.translate('lblUsername');
        this.lblPassword = this.translation.translate('lblPassword');
        this.login_Text = this.translation.translate('login_Text');
        this.hrDescription = this.translation.translate('hrDescription');
        this.lblLanguage = this.translation.translate('lblLanguage');
        this.enter_Text = this.translation.translate('enter_Text');
        this.respawn_label = this.translation.translate('respawn_label');

      }
    );

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    //RImuovo tutte le sottoiscrizioni agli osservabili
    this.subscriptions.forEach(subsctiption => {
      subsctiption.unsubscribe()
    })
  }
  public login() {
    if (this.loginInfo.username == '' || this.loginInfo.password == '') {
      return;
    }


    this.tryLogin = true;
    this.subscriptions.push(
      this.authService.login(this.loginInfo).subscribe(
        (data) => {
          if (data) {
            let obj = { countryCode: this.locale.getCurrentCountry() }

            this.l10nLoader.load().then(
              data => {
                console.warn("Implementare controllo password scaduta (in attesa di API)")
                this.router.navigate(['dashboard'])
              }
            )
          } else {
            this.tryLogin = false;
            this.loginFail = true;
          }
        },
        (error: HttpErrorResponse) => {

          if (error.error.error_code === 'error.login.passwordexpired') {
            this.l10nLoader.load().then(
              data => {
                this.router.navigate(['password-change'], {})
              })
          } else {
            this.login_error_message = error.error.error_description
            console.log(this.login_error_message)
            console.log("LOGIN ERROR", error);
            this.tryLogin = false;
            this.loginFail = true;

            this.cdr.detectChanges();
          }
        }
      )
    )

  }

  public setLocalInfo(language: string) {

    //Aggiorno il file di configurazione della libreria
    let defaultLocale = language.split('-');
    this.configuration.locale.defaultLocale.languageCode = defaultLocale[0];
    this.configuration.locale.defaultLocale.countryCode = defaultLocale[1];

    //Aggiorno la lingua dell'API
    apiLanguage.next(language);
    localStorage.setItem('defaultLocale', language);

    //Ricarico le traduzioni con la configurazione aggiornata
    this.l10nLoader.load()
  }

  public showRequestResetPassword() {
    this.router.navigateByUrl('/login/reset')
  }

}
