import { Component, OnInit, Input, Output, ɵConsole, SimpleChanges } from '@angular/core';
import { VersioningService, VersioningClass } from '@services/versioning.service';
import { finalize } from 'rxjs/operators';
import { Language, DefaultLocale, Timezone } from 'angular-l10n';
import { AppGlobals } from '@root/app/globals';
import { Subscription } from 'rxjs';
import { AclService } from '@services/acl.service';

//TODO ACL
@Component({
  selector: 'app-versioning',
  templateUrl: './versioning.component.html',
  styleUrls: ['./versioning.component.scss']
})
export class VersioningComponent implements OnInit {
  @Input() pkEntity: any;
  @Input() nameEntityLock: string;
  @Input() nameEntity: string;
  display: boolean = false;
  versioningList: VersioningClass[] = []
  versioningRecord: VersioningClass = new VersioningClass();
  versioningError: string = "";
  isLock: boolean;
  subscriptions: Subscription[] = [];
  @Language() lang: string;
  @DefaultLocale() defaultLocale: string;
  @Timezone() timezone: string;
  constructor(
    private versioningService: VersioningService,
    public _appGlobals: AppGlobals,
    private aclService: AclService
  ) { }


  ngOnInit() {
    this.subscriptions.push(
      //Controllo lo stato del lock
      this._appGlobals.getLock(this.nameEntityLock).subscribe(
        data => {
          this.isLock = data.is_lock;
        }
      )
    )
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.forEach(subsctiption => {
      subsctiption.unsubscribe()
    })
  }

  //Lista versioni record
  public getVersioning() {
    this.subscriptions.push(
      this.versioningService.getVersioningList(this.pkEntity, this.nameEntity)
        .pipe(
          finalize(() => this.display = true)
        )
        .subscribe(
          versioning => { this.versioningList = versioning }
        )
    )
  }

  //Catturo la chiusa del tab dell'accordion
  public onTabClose($event) {
    this.versioningError = "";
    this.versioningRecord = new VersioningClass();
  }

  //Catturo l'apertura del tab dell'accordion
  public onTabOpen($event) {
    this.versioningRecord = new VersioningClass();
    this.versioningError = "";
    let version = this.versioningList[$event.index];
    this.subscriptions.push(
      this.versioningService.getVersion(version.id).
        subscribe(
          version => this.versioningRecord = version,
          error => {
            this.versioningError = error.error.detail
          }
        )
    )
  }

  //Ripristino versione
  public restoreRecord(versioning: VersioningClass) {
    this.subscriptions.push(
      this.versioningService.setRestoreVersion(versioning).subscribe(
        success => location.reload(),
        error => {
          switch (error.status) {
            case 500:
              this.versioningError = error.error.title;
              break;
            default:
              this.versioningError = error.error.validationErrors[0].description;
              break;
          }
        },
      )
    )
  }

  //Controllo Lock & ACL
  public isDisabled() {
    return (!this.aclService.canRestoreRecord() || !this.isLock) ? true : false;
  }

  public closeDialog() {
    this.display = false;
    this.versioningRecord = new VersioningClass()
  }

}
