import { Injectable, Inject } from '@angular/core';
import { TranslationProvider, LocaleService, L10N_CONFIG, L10nConfigRef } from 'angular-l10n';
import { HttpClient, HttpParams, } from '@angular/common/http';
import { Observable, of, concat } from 'rxjs';
import { tap, map, catchError, take } from 'rxjs/operators';
import { api, baseurl } from '@root/app/app.component'
import { AuthService } from '@services/auth/auth.service'

@Injectable() export class CustomTranslationProvider implements TranslationProvider {

  constructor(
    private http: HttpClient,
    public locale: LocaleService,
    private authService: AuthService,
    @Inject(L10N_CONFIG) private configuration: L10nConfigRef
  ) {
  }


  public getTranslation(language: string, args: any): Observable<any> {
    let url = ""
    if (this.authService.isLogged()) {
      url = api + 'stringresource/all';
    } else {

      url = api + 'stringresource/login';
    }
    return this.http.get(url).pipe(
      catchError(err => {
        return of(err)
      })
    )
  }


  public getLoginLabels(language: string) {
    return this.http.get<any>(baseurl + language + '/stringresource/login')
  }

  //Chiamata elenco lingue con in aggiunta la lingua di default impostata nel browser
  public getLanguages(): Observable<any> {
    return this.http.get<any>(baseurl + navigator.language + '/language/lookup')
  }



}
