import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfiguration {
  constructor(private httpClient: HttpClient){

  }

  apiUrl: string;

  ensureInit(): Promise<any> {
    return new Promise((r, e) => {

      this.httpClient.get('./assets/config.json')
        .subscribe(
          (content: AppConfiguration) => {
            Object.assign(this, content);
            r(this);
          },
          reason => e(reason));
    });
  }
}
