import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { api } from '@root/app/app.component';


export interface UserInterface {
  usrId: number,
  //id_role: number,
  usrName: string,
  usrSurname: string,
  usrLogin: string,
  usrEmail: string,
  usrPhone: any,
  usrMobile: any,
  // password: any,
  usrActive: boolean,
  usrLocked: boolean,
  usrTsLocked: Date
  deleted: boolean
}

export class UserClass implements UserInterface {
  usrId = 0;
  // id_role = 3;
  usrName = "";
  usrSurname = "";
  usrLogin = "";
  usrEmail = "";
  usrPhone = "";
  usrMobile = "";
  //password = "";
  usrActive = false;
  usrLocked = false;
  usrTsLocked = null;
  deleted = false;
}


export interface UserCustomerInterface {
  id: number,
  custId: number,
  usrId: number
  custDescr: string,
  custLogo: string,
  rlId: number,
  rlDescr: string,
  grpId: number,
  grpDescr: string,
  usrActive: boolean,
  usrServer: string,
  usrServerDescr: string,
  srvId: number,
  srvName: string,
  url: string
  tsM: Date;
  deleted: boolean
}
export class UserCustomerClass implements UserCustomerInterface {
  id = 0;
  usrId = 0;
  custId = 0;
  custDescr = "";
  custLogo = "";
  rlId = undefined;
  rlDescr = "";
  grpId = undefined;
  grpDescr = "";
  usrActive = true;
  usrServer = "";
  usrServerDescr = "";
  srvId = undefined;
  srvName = "";
  url = "";
  srvPathDocroot = undefined;
  srvDbHostName = undefined;
  tsM = null;
  deleted = false;
  constructor(usrId?: number) {
    if (usrId !== null) {
      this.usrId = usrId
    }
  }
}

@Injectable({
  providedIn: 'root'
})



export class UserService {

  constructor(
    private http: HttpClient

  ) { }

  private fake = 'http://localhost:3000/'

  public getUsers(offset: string, rows: string, sort: string, sortcol: string, filter: string): Observable<UserClass[]> {
    if (filter === null) {
      filter = ''
    }
    let params = {
      offset: offset,
      rows: rows,
      sort: sort,
      sortcol: sortcol,
      filter: filter
    }
    return this.http.get<UserClass[]>(api + 'user/', { params: params })
  }

  public getUsersAll() {

    return this.http.get<UserClass[]>(api + 'user')
  }


  public getUser(id: number): Observable<UserClass> {
    return this.http.get<UserClass>(api + 'user/' + id);
    // return this.http.post<UserClass>(api + 'users/' + id,'');
  }


  public prova() {
    return this.http.get('http://localhost:53753/it-IT/user/server/1/72');
  }
  public getUserServers(usrId: number): Observable<UserCustomerClass[]> {
    return this.http.get<UserCustomerClass[]>(api + 'user/server/' + usrId);
    // return this.http.get<UserCustomerClass[]>(api + 'user/customer/' + usrId);
  }

  public getUserServer(srvId: number, usrId: number): Observable<UserCustomerClass> {
    //{{localhost}}/it-IT/user/customer/1/18 
    return this.http.get<UserCustomerClass>(api + 'user/server/' + srvId + '/' + usrId);
  }

  public createUser(user: UserClass): Observable<any> {
    return this.http.post(api + 'user', user)
  }

  public updateUser(user: UserClass): Observable<any> {
    return this.http.put(api + 'user/' + user.usrId, user)
  }

  public activeUser(usrId: number): Observable<any> {
    return this.http.post(api + 'user/' + usrId + '/enable', '')
  }

  public deactiveUser(usrId: number): Observable<any> {
    return this.http.post(api + 'user/' + usrId + '/disable', '')
  }

  public lockUser(usrId: number): Observable<any> {
    return this.http.post(api + 'user/' + usrId + '/lock', '')
  }

  public unlockUser(usrId: number): Observable<any> {
    return this.http.post(api + 'user/' + usrId + '/unlock', '')
  }

  public deleteUser(usrId: number): Observable<any> {
    return this.http.delete(api + 'user/' + usrId);
  }

  public createUserCustomer(userCustomer): Observable<any> {
    //return this.http.post(api + 'user/customer', userCustomer);
    return this.http.post(api + 'user/server', userCustomer);
  }

  public updateUserServer(userCustomer: UserCustomerClass): Observable<any> {
    return this.http.put(api + 'user/server/' + userCustomer.srvId + '/' + userCustomer.usrId, userCustomer);
    ///user/customer/1/666
    //return this.http.put(api + 'user/customer/'+userCustomer.custId+'/'+userCustomer.usrId,userCustomer);
    //return this.http.put(api + 'user/customer/?usrId=' + userCustomer.usrId+'&custId='+userCustomer.custId, userCustomer);
  }

  public deleteUserServer(srvId: number, usrId: number): Observable<any> {
    return this.http.delete(api + 'user/server/' + srvId + '/' + usrId);

    //return this.http.delete(api+'user/customer/'+userCustomer.custId+'/'+userCustomer.usrId);
  }

  public activeUserCustomer(userCustomer: UserCustomerClass): Observable<any> {
    return this.http.post(api + 'user/customer/' + userCustomer.custId + '/' + userCustomer.usrId + '/enable', '')
  }

  public deactiveUserCustomer(userCustomer: UserCustomerClass): Observable<any> {
    return this.http.post(api + 'user/customer/' + userCustomer.custId + '/' + userCustomer.usrId + '/disable', '')
  }

  public respawnPassword(usrId: number): Observable<any> {
    return this.http.post(api + 'user/' + usrId.toString() + '/resetpassword', {}).pipe(
      map(
        data => {
          console.log('respawnData: ', data);
        }
      )
    )
  }
}
