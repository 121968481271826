import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { LoginComponent } from '@root/app/components/login/login.component';
import { MenuLeftTemplateComponent } from './layouts/menu-left-template/menu-left-template.component';

import { RoleGuardService } from '@services/guards/role-guard.service'
import { LoginGuardService } from '@services/guards/login-guard.service'
import { CanActivate } from '@angular/router/';
import { ChangePasswordComponent } from '@root/app/components/login/change-password/change-password.component';
import { ResetPasswordComponent } from './components/login/resetPassword/resetPassword.component';
import { LogoutComponent } from '@components/logout/logout.component';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload'
};


const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoginGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: LoginComponent,
        pathMatch: 'full'
      },
      {
        path: 'reset',
        component: ResetPasswordComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'password-change',
    component: ChangePasswordComponent,
    canActivate: [RoleGuardService]
  },
  {
    path: '',
    component: MenuLeftTemplateComponent,
    data: { title: 'UI.home', breadcrumb: 'UI.homeBreadcrumb' },
    canActivate: [RoleGuardService],
    //loadChildren: '@root/app/shared/sg-common/sg-common.module#SgCommonModule',

    loadChildren: () => import('@root/app/shared/sg-common/sg-common.module').then(m => m.SgCommonModule),
  },
  {
    path: 'logout',

    canActivate: [RoleGuardService],
    children: [
      {
        path: '',
        component: LogoutComponent,
        pathMatch: 'full'
      }
    ]
  },

  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full',
    canActivate: [RoleGuardService]
  },


]

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
