import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { api, baseurl } from '@root/app/app.component'
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, catchError, share, finalize, concatMap } from "rxjs/operators";
import { LocaleService } from 'angular-l10n';
import { AclService, aclKeyStorage } from '../acl.service'
import * as jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient,
    private locale: LocaleService,
    private aclService: AclService
  ) { }

  @Output() is_logged: EventEmitter<boolean> = new EventEmitter();

  isLogged(): boolean {
    if (localStorage.getItem('refresh_token') != null) {
      return true
    } else {
      return false
    }
  }

  public userMustChangePw() {
    if (localStorage.getItem('userMustChangePw') != null) {
      return true
    } else {
      return false
    }
  }

  public login(params: object): Observable<any> {
    //TODO info utente & ruolo
    let httpParams = {
      username: params['username'],
      password: params['password']
    }
    // let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(baseurl + params['language'] + '/login', httpParams).pipe(
      map(
        (data) => {
          if (data['error_code'] === null) {
            this.setToken(data['access_token'], data['refresh_token'])
            this.initUserData(data['access_token']);
            return true;
          }
          else {
            return false;
          }
        }
      ),
      catchError(err => {

        if (err.error['error_code'] === 'error.login.passwordexpired') {
          this.setToken(err.error['access_token'], err.error['refresh_token'])
          this.setUserMustChangePw(true);
        }
        return throwError(err);
      }),
      finalize(
        () => {

        }
      )

    )
  }

  public getServerJWT(srvId: string): Observable<any> {
    let params: Object = {
      "serverid": srvId
    }
    return this.http.post(api + 'token/auth', params)
  }

  public logout(): Observable<any> {
    return this.http.post(api + 'token/logout', null);
  }

  public refreshToken(): Observable<any> {
    let rt = this.getRefreshToken()
    let params: Object = {
      refresh_token: rt
    }
    let language = this.getCurrentLanguage();

    return this.http.post(baseurl + language + '/token/refresh', params).pipe(
      map(
        data => {
          this.setToken(data['access_token'], data['refresh_token'], true)
        }
      )
    )
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    let params: Object = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
    let language = this.getCurrentLanguage()
    return this.http.post(baseurl + language + '/login/changepassword', params).pipe(
      map(
        data => {
          this.setToken(data['access_token'], data['refresh_token'], true)
          this.setUserMustChangePw(false);
          this.initUserData(data['access_token']);
          return data;
        }
      ),
      catchError(error => {
        return of(error)
      })
    )

  }

  public requestResetPassword(usrLogin: string): Observable<any> {
    let params: Object = {
    }
    let language = this.getCurrentLanguage();
    //  return this.http.post(baseurl + this.locale.getCurrentLocale() + '/login/resetpassword/' + usrLogin, params).pipe(
    return this.http.post(baseurl + language + '/login/resetpassword/' + usrLogin, params).pipe(
      map(
        data => {
          console.log('ResetData: ', data);
        }
      )
    )
  }

  public resetPassword(guid: string): Observable<any> {
    let language = this.getCurrentLanguage();
    return this.http.get(baseurl + language + '/login/resetpassword/' + guid).pipe(
      map(
        data => {
          console.log('ResetData: ', data);
        }
      )
    )
  }

  public serverLogin(srvUrl: string, access_token: string) {
    var form = document.createElement("form");
    form.setAttribute("target", "_blank");
    form.setAttribute("method", "POST");
    // form.setAttribute("action", srvUrl + '/frmLoginJWT.aspx');
    form.setAttribute("action", srvUrl);
    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "access_token");
    hiddenField.setAttribute("value", access_token);
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();

  }

  public serverLoginNew(srvUrl: string, token: string, openMode: string = "_blank") {
    //   window.open(srvUrl + '?token=#'+refresh_token);
    //window.location.href= srvUrl + '?token='+encodeURIComponent(token);
    let element = srvUrl.includes('?') ? '&' : '?';
    let baseurl: string = srvUrl + element + 'token=' + encodeURIComponent(token) + '&lang=' + this.getCurrentLanguage();
    if (openMode == '_blank') {
      window.open(baseurl);
    }
    if (openMode == '_self') {
      window.location.href = baseurl;
    }

  }

  public setToken(token: string, refresh_token: string, notify: boolean = false) {
    //notify indica se inviare l'evento agli altri tab
    localStorage.setItem('refresh_token', refresh_token);

    if (notify) {
      var payload = {
        access_token: token,
        refresh_token: refresh_token
      }

      localStorage.setItem('newRefreshToken', JSON.stringify(payload));
      localStorage.removeItem('newRefreshToken');
    }
  }

  private setUserMustChangePw(flag: boolean) {
    if (flag) {
      localStorage.setItem('userMustChangePw', flag.toString())
    } else {
      localStorage.removeItem('userMustChangePw')
    }

  }

  private setInfoUser(access_token: string) {
    localStorage.setItem('userId', jwtDecode(access_token).sub);
  }

  public getInfoUser(): number {
    return parseInt(localStorage.getItem('userId'));
  }

  public getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  private initUserData(access_token: string) {

    this.setInfoUser(access_token)
    // this.setToken();       
    //effettuo la chiamata per recuperare le ACL.
    this.aclService.getAcl().pipe(
      map(
        (data: Response) => {
          localStorage.setItem(aclKeyStorage, JSON.stringify(data))
        }
      )
    ).subscribe()

  }

  private getCurrentLanguage() {
    return (localStorage.getItem('defaultLocale') !== null) ? localStorage.getItem('defaultLocale') : this.locale.getConfiguration().defaultLocale.languageCode + '-' + this.locale.getConfiguration().defaultLocale.countryCode
  }

}
