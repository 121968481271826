import { Component, OnInit } from '@angular/core';
//import { AppInjector } from '@root/app/service/app-injector.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Language, TranslationService } from 'angular-l10n';
@Component({
  selector: 'app-lock-wrapper',
  templateUrl: './lock-wrapper.component.html',
  styleUrls: ['./lock-wrapper.component.css']
})
export class LockWrapperComponent {

  @Language() lang: string;

  //protected utilitiesService: UtilitiesService;    
  //protected loggingService: LoggingService;
  protected subscriptions: Subscription[] = []
  protected isLockedByMe: boolean
  protected tbName: string = "";
  protected isLoaded: boolean = false;
  protected pk: number;
  protected insertMode: boolean = false;
  protected dlgConcurrencySave: boolean = false;
  protected pkName: string;
  protected detail: any;
  protected detailBk: any;
  protected btnSaveLabel: string = 'Salva'
  public translation: TranslationService

  get btnSave() {

    return this.translation.translate((this.insertMode) ? 'UI.btnCreate' : 'UI.btnSave')
  }

  constructor(
    protected activeRoute: ActivatedRoute,

  ) {
    // Manually retrieve the dependencies from the injector    
    // so that constructor has no dependencies that must be passed in from child    
    //const injector = AppInjector.getInjector();
    //this.testService = injector.get(TestService);    

  }

  protected loadData(id, mode) {
  }

  startup() {
    this.activeRoute.params.subscribe(
      params => {
        this.pk = params['id']

        if (isNaN(this.pk)) {
          this.insertMode = true;
        }

        this.loadData(this.pk, this.insertMode)

      })
  }

  public isDisabled() {
    return (this.detail[this.pkName] === 0) ? false : (this.isLockedByMe) ? false : true
  }

}



