import { Directive, ElementRef, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { AclService } from '../service/acl.service';

@Directive({
  selector: '[checkAcl]'
})



export class AclDirective implements OnInit {
  constructor(
    private aclService: AclService,
    private el: ElementRef,
  ) {

  }

  @Input() checkAcl: string;
  @Output() isPermission: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
    //Controllo se l'utente ha il permesso di modifica
    if(this.aclService.getPermission( this.checkAcl) === 2){
      this.isPermission.emit(true);
    }else{
      this.isPermission.emit(false);
    }
  }

} 
