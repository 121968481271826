import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@root/app/service/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Language, DefaultLocale, TranslationService } from 'angular-l10n';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Language() lang: string;
  @DefaultLocale() defaultLocale: string;
  @Input() redirectAfterChange: boolean = true;
  @Output() closeChange: EventEmitter<boolean> = new EventEmitter();
  loginInfo = {
    "oldPassword": '',
    "password": '',
    "passwordConfirm": ''
  }

  lblOldPw: string = 'ChangePassword.lblOldPw'
  lblPassword: string = 'ChangePassword.lblPw'
  lblPasswordConfirm: string = 'ChangePassword.lblPwConfirm'
  changePassword_error_message: string = ''
  changePwFail: boolean = false
  changePwSuccess: boolean = false
  isDisable: boolean = false;
  changePassword_Text: string = "";
  btnConfirm: string = "";
  btnCancel: string = "";
  constructor(
    private authService: AuthService,
    private translation: TranslationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.changePassword_Text = this.translation.translate('ChangePassword.changePassword_Text');
    this.btnConfirm = this.translation.translate('ChangePassword.btnConfirm');
    this.btnCancel = this.translation.translate('UI.btnCancel');
  }

  changePassword() {

    if (!this.formIsValid()) {
      return
    }
    this.isDisable = true;
    this.authService.changePassword(this.loginInfo.oldPassword, this.loginInfo.password).subscribe(
      (data) => {
        if (data.error == null) {
          this.changePwSuccess = true;
          this.changePwFail = false;
          var me = this;
          //Eseguo il redirect solo se la proprietà è impostata a true
          if (this.redirectAfterChange) {
            setTimeout(function () {
              document.location.href = '/dashboard'
            }, 3500)
          } else {
            this.closeChange.emit(true);
            this.isDisable = false;
            this.loginInfo = {
              "oldPassword": '',
              "password": '',
              "passwordConfirm": ''
            }
          }
        } else {
          this.changePassword_error_message = data.error.error_description
          this.changePwFail = true;
          this.isDisable = false;
        }
      }
    )
  }

  public closeChangePassword() {
    this.loginInfo = {
      "oldPassword": '',
      "password": '',
      "passwordConfirm": ''
    };
    this.changePwFail = false;
    this.closeChange.emit(true);
  }




  private formIsValid(): boolean {
    if (
      this.loginInfo.oldPassword == ''
      ||
      this.loginInfo.password == ''
      ||
      this.loginInfo.passwordConfirm == ''
    ) {
      this.changePwFail = true
      this.changePassword_error_message = this.translation.translate('UI.lblChangePwdMandatoryFieldError')
      return false;
    } else if (this.loginInfo.passwordConfirm !== this.loginInfo.password) {
      this.changePwFail = true
      this.changePassword_error_message = this.translation.translate('UI.lblChangePwdConfirmMatchError')

    } else {
      this.changePwFail = false
      return true;
    }
  }
}
