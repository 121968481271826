import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { api,baseurl } from '@root/app/app.component'
import { Observable, of, throwError } from 'rxjs';
//import { JsonPipe } from '@angular/common';
//import { platform } from 'os';

export const aclKeyStorage:string="acl";

export interface getInfoInterface{
  usrC:string
  usrEmailC:string
  grpDescrC:String
  tsC:Date
  usrM:string
  usrEmailM:string
  grpDescrM:string
  tsM: Date
}

export class GetInfoClass{
  usrC:string
  usrEmailC:string
  grpDescrC:String
  tsC:Date
  usrM:string
  usrEmailM:string
  grpDescrM:string
  tsM: Date
}

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http:HttpClient) { }
  
  public getInfo(pk:number,tableName:string, schemaName:string = ""):Observable<getInfoInterface>{

    let params = {
      schemaName:schemaName,
      tableName:tableName,
      pk:pk.toString()
    }

    return this.http.get<getInfoInterface>(api+'audit/getinfo/',{params: params})
  }
}

