import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth/auth.service'
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { CMSCONFIG } from '@root/app/globals';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService {

  constructor(
    private authService: AuthService,private router:Router
  ) { }
  canActivate(): boolean {
    if(this.authService.isLogged()){
//      this.router.navigate(['login'])
      
      this.router.navigate([CMSCONFIG.redirectToAfterLogin]);
      return false;
    }else{
      return true;
    } 
  }
}
