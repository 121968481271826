import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Injectable()
export class SessionRestoreProvider {

  //private resolve: Promise<any> = null;

  constructor(
    private authService: AuthService
  ) {

  }

  load() {

    var as = this.authService;

    // Non devo fare nulla, ho già la sessione, risolvo subito
    return new Promise<boolean>((resolve) => {
      resolve(true);
    })

  }
}



export function sessionRestoreProviderFactory(provider: SessionRestoreProvider) {
  return () => provider.load();
}