import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
//import {SharedModule} from 'primeng/primeng'
import { AccordionModule } from 'primeng/accordion';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CommonModule } from '@angular/common';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    TabMenuModule,
    AccordionModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    SidebarModule,
    TabViewModule,
    MenuModule,
    TableModule,
    TooltipModule,
    CheckboxModule,
    ToastModule,
    DropdownModule,
    ListboxModule,
    ProgressSpinnerModule,
    InputMaskModule,
    //MessagesModule,
    MessageModule,
    DialogModule,
    InputTextareaModule,
    FieldsetModule,
    KeyFilterModule
  ],
  exports: [
    CommonModule,
    TabMenuModule,
    AccordionModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    SidebarModule,
    TabViewModule,
    MenuModule,
    TableModule,
    TooltipModule,
    CheckboxModule,
    ToastModule,
    DropdownModule,
    ListboxModule,
    ProgressSpinnerModule,
    InputMaskModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    InputTextareaModule,
    FieldsetModule,
    KeyFilterModule
  ],
  providers: [ConfirmationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class Prime_NgModule { }
