//import {Observable} from 'rxjs/internal/Observable';
//import 'rxjs/add/operator/share';
//import 'rxjs/add/operator/startWith';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Injectable, Output, EventEmitter } from "@angular/core"
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


export interface LockEntityInterface {
  tbName: string,
  is_lock: boolean,
  usrLoginLock: string,
  usrIdLock: number
}

export class LockEntityClass implements LockEntityInterface {
  tbName = ""
  is_lock = false
  usrLoginLock = null
  usrIdLock
}

@Injectable()
export class AppGlobals {
  constructor() {
    console.warn("TODO: Dinamicizzare paramentro CMSCONFIG.custId");
  }
  // use this property for property binding
  public pageTitle: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public tableDefaultRow: BehaviorSubject<number> = new BehaviorSubject<number>(10)

  private _pageTitle = "";

  /*private lockEntity = [
    {
      tbName: "User",
      is_lock: false,
      usrLoginLock:null

    },
    {
      tbName: "Server",
      is_lock: false,
      usrLoginLock:null
    },
    {
      tbName: "Config",
      is_lock: false,
      usrLoginLock:null
    },
    {
      tbName: "Customer",
      is_lock: false,
      usrLoginLock:null
    }
  ];*/

  private lockEntity: Array<LockEntityClass> = []

  public isLock: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(this.lockEntity);

  setPageTitleVariant(title) {
    this._pageTitle = title;
    this.pageTitle.next(title);
  }

  setLockState(tbName: string, state: boolean, usrLoginLock: string, data: any = null) {
    let foundIndex = this.lockEntity.findIndex(x => x.tbName == tbName)
    let myEntity;

    if (foundIndex == -1) {
      myEntity = new LockEntityClass()
      myEntity.tbName = tbName
      this.lockEntity.push(myEntity)
    } else {
      myEntity = this.lockEntity[foundIndex]
    }

    myEntity.is_lock = state
    myEntity.usrIdLock = (data) ? data.usrIdLock : null
    myEntity.usrLoginLock = (usrLoginLock === null) ? "[unlocked]" : usrLoginLock
    this.isLock.next(this.lockEntity)
  }

  setFocus(id: string) {
    let domEl = document.getElementById(id);
    domEl.focus();
  }

  getLock(tbName: string): Observable<any> {
    return this.isLock.pipe(
      map(
        data => {
          let foundIndex = data.findIndex(x => x.tbName == tbName)
          let myEntity;
          if (foundIndex == -1) {
            myEntity = new LockEntityClass()
            myEntity.tbName = tbName
            this.lockEntity.push(myEntity)
          } else {
            myEntity = this.lockEntity[foundIndex]
          }

          return myEntity
        }
      )
    )
  }
}

export const CMSCONFIG = {
  redirectToAfterLogin: 'dashboard',
  redirectToAfterLogout: 'login',
  defaultRoute: '/dashboard',
  custIdDefault: 1,
  //custId: 1,
  parameters: { custId: 1 }
}

export var USER = {
  usrId: null,
  grpId: null
}