import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges, SimpleChange, ChangeDetectorRef, ViewChild } from '@angular/core';
import { LockService, LockUnlockResult, LockUnlockClass } from '@root/app/service/lock.service';
import { switchMap, concatMap, mergeMap, finalize, switchAll } from 'rxjs/operators';
import { of, Observable, observable, BehaviorSubject, iif, Subscription } from 'rxjs';
import { AppGlobals } from '@root/app/globals'
import { TranslationService, Language, DefaultLocale } from 'angular-l10n';
import { AclDirective } from '@directives/acl.directive'
import { UserService, UserClass } from '@root/app/service/user.service';
import { OverlayPanelModule, OverlayPanel } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';

import { AuditService, GetInfoClass } from '@root/app/service/audit.service';
import { AclService } from '@root/app/service/acl.service';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {
  lockUnlock: LockUnlockResult;
  @Input() pkEntity: number = null;
  @Input() tbName: string = null;
  @Input() pkEntityVersioning: number = this.pkEntity
  @Input() tbNameVersioning: string = this.tbName;
  @Input() implicit = true;
  @Input() showVersioning = true;
  destroyLock: boolean = this.implicit; //Se il valore viene impostato su false non viene effettuato l'unlock implicito, di default è true 
  setImplicitLock: boolean = this.implicit; //Se il valore viene impostato su false non viene effettuato il lock implicito, di default è true
  //@Input() isLock: boolean;
  @Language() lang: string;
  @DefaultLocale() defaultLocale: string;
  @Output() lockChange: EventEmitter<Boolean> = new EventEmitter();
  @ViewChild('uid', { static: false }) uInfoDialog: OverlayPanel;

  permissionKey: string = "UNLOCKOBJECT";
  isPermissionACL: boolean = false;
  isLock: boolean = false;
  usrLoginLock: string = null;
  subscriptions: Subscription[] = []
  check_lock = new Observable()
  userInfoDetail: GetInfoClass;
  isInfoDialogVisible: boolean = false;
  usrIdLock: number;
  isLoad: boolean = false;
  get lockClass() {


    //{'fa-lock': !isLock, 'text-danger': !isLock , 'text-success': isLock }

    if (!this.isPermissionACL && !this.isLock) {
      return '';
    }

    if (this.isLock) {
      return 'ui-button-success  ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left';
    }

    if (!this.isLock) {
      if (this.usrLoginLock == '[unlocked]') { // Non è in uso da nessuno
        return 'ui-button-info  ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left';
      }

      return 'ui-button-danger  ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left';

    }

  }


  get lockText() {

    if (!this.isLock && this.usrLoginLock != null && this.usrLoginLock.length > 0) {
      if (this.usrLoginLock != '[unlocked]') {
        return this.translation.translate('UI.lockedBy') + this.usrLoginLock
      } else {
        return this.translation.translate('UI.unlocked')
      }
    } else {
      return this.translation.translate('UI.lockedByMe')
    }

  }
  constructor(
    private lockService: LockService,
    private auditService: AuditService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    public _appGlobals: AppGlobals,
    protected translation: TranslationService,
    private aclService: AclService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Controllo se la variabile di input per la gestione del lock/unlock implicito ha subito variazioni
    if (changes.implicit !== undefined) {
      this.destroyLock = changes.implicit.currentValue;
      this.setImplicitLock = changes.implicit.currentValue;
    }

    if (changes.pkEntity.previousValue !== changes.pkEntity.currentValue) {
      this.pkEntity = changes.pkEntity.currentValue;
    }

    if (changes.tbName.previousValue !== changes.tbName.currentValue) {
      this.tbName = changes.tbName.currentValue;
    }

    if (changes.pkEntityVersioning !== undefined) {
      this.pkEntityVersioning = changes.pkEntityVersioning.currentValue
    }

    if (changes.tbNameVersioning !== undefined) {
      this.tbNameVersioning = changes.tbNameVersioning.currentValue
    }

  }

  ngOnInit() {
    //this.tbNameVersioning = (this.tbNameVersioning === undefined) && this.tbName;
    // this.pkEntityVersioning = (this.pkEntityVersioning === undefined) && this.pkEntity;

    this.tbNameVersioning === undefined && (this.tbNameVersioning = this.tbName);
    this.pkEntityVersioning === undefined && (this.pkEntityVersioning = this.pkEntity)
    //Controllo lo stato dell'entità
    //Controllo se ho i permessi per verificare e prendere il lock in modo implicito

    if (this.aclService.getPermission(this.permissionKey) === 2 && this.setImplicitLock) {
      this.check_lock = this.lockService.getLockCheckAndGet(this.pkEntity, this.tbName)

    } else {
      //In alternativa verifico solo lo stato di lock
      this.check_lock = this.lockService.getLockCheckEntity(this.pkEntity, this.tbName)
    }

    this.subscriptions.push(
      this.check_lock
        .pipe(
          finalize(() => this.isLoad = true)
        )
        .subscribe(
          (data: LockUnlockResult) => {
            this._appGlobals.setLockState(this.tbName, data.isEditable, data.usrLoginLock, data)
            this.lockUnlock = data
          }
        )
    )

    /*
    this.lockService.getLockUnlockEntity(this.pkEntity, this.tbName).subscribe(
      data => {
        this._appGlobals.setLockState(this.tbName, data.isEditable, data.usrLoginLock)
        this.lockUnlock = data
      }

*/

    this._appGlobals.getLock(this.tbName).subscribe(
      data => {
        if (data) {
          this.isLock = data.is_lock;
          this.usrLoginLock = data.usrLoginLock
          this.usrIdLock = data.usrIdLock
        }
      }
    )
    //  this.isLock = this._appGlobals.getLockState(this.tbName)


    this.subscriptions.push(this._appGlobals.getLock(this.tbName).subscribe(
      data => {
        this.isLock = data.is_lock;
        this.lockChange.emit(this.isLock);
      }
    ))

  }

  public setLock(state: boolean) {
    //this.isLock.next(state);
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }


  ngOnDestroy(): void {
    //Effettuo l'unlock implicito solo se ho tutti i permessi necessari
    if (this.isLock && this.destroyLock && this.isPermissionACL) {
      this.unlockRecord()
    }
    //RImuovo tutte le sottoiscrizioni agli osservabili
    this.subscriptions.forEach(subsctiption => {
      subsctiption.unsubscribe()
    })
  }

  public unlockRecord() {
    let lockUser = new LockUnlockClass(this.tbName, this.pkEntity)
    this.subscriptions.push(
      this.lockService.setUnlockEntity(lockUser).subscribe(
        data => {
          this._appGlobals.setLockState(this.tbName, false, null)
          //this.lockUnlock.usrLoginLock = null
          //this.setLock(false);
          /*
          this.lockChange.emit(false);
          this.lockUnlock.isEditable = false*/
        }
      )
    )
  }

  public lockRecord() {
    let lockUser = new LockUnlockClass(this.tbName, this.pkEntity)
    let a = true
    let observable = new Observable(observer => {

      //Controllo se devo richiedere lo sblocco del lock prima di bloccarlo
      if (this.lockUnlock.usrLoginLock) {
        observer.next(this.lockService.setUnlockEntity(lockUser).subscribe())
      }
      observer.next(this.lockService.setLockEntity(lockUser).subscribe())
    })
    //Eseguo le chiamate
    observable.subscribe(
      data => {
        this._appGlobals.setLockState(this.tbName, true, null, data)
      },
      error => {
        this._appGlobals.setLockState(this.tbName, false, null)
      }
    )
  }


  public checkAcl(event: boolean) {
    this.isPermissionACL = event;
  }

  public getUserInfo() {
    this.auditService.getInfo(this.pkEntity, this.tbName).subscribe(
      data => {
        this.userInfoDetail = data
        this.isInfoDialogVisible = true;
      }
    )
  }
}