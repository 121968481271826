import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { LockUnlockResult, LockService, LockUnlockClass, LockUnlockInterface, } from '@root/app/service/lock.service';
import { UserService } from '@services/user.service'
import { CustomerService } from '@services/customer.service'
import { Observable, of, BehaviorSubject, concat } from 'rxjs';
import { api } from '@root/app/app.component'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, finalize, concatMap, delay, catchError, } from 'rxjs/operators';
import { AppGlobals } from '@root/app/globals'
import { Language } from 'angular-l10n';

export enum choices {
  Reload = 'RELOAD',
  Overwrite = 'OVERWRITE',
  Cancel = 'CANCEL'
}

@Component({
  selector: 'app-lock-error-confirm',
  templateUrl: './lock-error-confirm.component.html',
  styleUrls: ['./lock-error-confirm.component.css'],
  providers: [ConfirmationService]
})

export class LockErrorConfirmComponent implements OnInit {
  public resultError: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public header: string = "";
  public message: string = "";
  public apiCallGet: string;
  public apiCallPut: string;
  public object: any;
  public tbName: string;
  public pk: number;
  public errorLockDialog: boolean = false;
  public usrLoginLock: string = null

  public onUserChoice = null;

  constructor(
    protected confirmationService: ConfirmationService,
    protected http: HttpClient,
    protected lockService: LockService,
    private _appGlobals: AppGlobals
  ) { }

  @Language() lang: string

  ngOnInit() {

  }

  //Funzione per estrarre un record
  public getLastRecord() {

    if (typeof this.onUserChoice === 'function') {
      this.onUserChoice(choices.Reload, null);
      return;
    }

    return this.http.get(api + this.apiCallGet)
      .pipe(
        map(
          data => {
            let obj = {
              status: 'get_record',
              object: data
            }
            //Aggiorno il lock
            this.lockService.getLockCheckEntity(this.pk, this.tbName).pipe(
              map(
                (data) => { this._appGlobals.setLockState(this.tbName, data.isEditable, data.usrLoginLock) }
              )
            ).subscribe()

            this.resultError.next(obj)
            this.errorLockDialog = false;
          }
        )
      ).subscribe();


  }

  //Funzione per aggiornare in modo forzato un record
  public subscribeRecord() {
    let lockUnlock = new LockUnlockClass(this.tbName, this.pk);

    if (typeof this.onUserChoice === 'function') {
      this.lockService.setLockEntity(lockUnlock).subscribe(
        data => {
          this.onUserChoice(choices.Overwrite, data);
        }

      )


      return;
    }




    this.lockService.setLockEntity(lockUnlock)//ottengo il lock
      .pipe(
        concatMap(() => this.http.put(api + this.apiCallPut, this.object)),

        catchError((err) => {
          this.getLastRecord();
          return of()
        }
        )
      ).subscribe(
        data => {
          let obj = {
            status: 'subscribe_record',
            object: data
          }
          this.resultError.next(obj)
          this.errorLockDialog = false;
        }

      )

    /*
    let lockUnlock = new LockUnlockClass(this.tbName, this.pk)
    this.lockService.setLockEntity(lockUnlock)
      .pipe(
        finalize(
          () => {
            this.http.put(api + this.apiCallPut, this.object).pipe(
              map(
                data => {
                  let obj = {
                    status: 'subscribe_record',
                    object: data
                  }
                  this._appGlobals.setLockState(this.tbName, true,  this.usrLoginLock)
                  this.resultError.next(obj)
                  this.errorLockDialog = false;

                }
              )
            ).subscribe()
          }
        )
      ).subscribe()*/
  }


  protected onCancel() {
    this.errorLockDialog = false;
    if (typeof this.onUserChoice === 'function') {
      this.onUserChoice(choices.Cancel, null);
      return;
    }
  }

  public setNoLock() {
    this._appGlobals.setLockState(this.tbName, false, null)
  }

}
