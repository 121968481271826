import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { api } from '@root/app/app.component';

export interface VersioningInterface{
  version: number,
  id: number,
  action: string,
  fileName: string,
  usrIdc: number,
  usrNameC: string,
  tsc: Date
}

export class VersioningClass implements VersioningInterface{
  version = null;
  id = null;
  action = "";
  fileName = "";
  usrIdc = null;
  usrNameC = "";
  tsc = new Date;
}

@Injectable({
  providedIn: 'root'
})
export class VersioningService {

  constructor(
    private http:HttpClient
  ) { }
  apiUrl = api+'objectversion/'

  public getVersioningList(pk:number,nameEntity:string):Observable<VersioningClass[]>{
    let params={
      obj:nameEntity,
      pk:pk.toString()
    }
    return this.http.get<VersioningClass[]>(this.apiUrl,{params:params})
  }

  public getVersion(version:number):Observable<any>{
    return this.http.get<any>(this.apiUrl+version)
  }
  public setRestoreVersion(version:VersioningClass):Observable<any>{
 // public setRestoreVersion(versionId:number,version:VersioningClass):Observable<any>{
    return this.http.post<any>(this.apiUrl+'restore/'+version.id,version);
  }
  
}
