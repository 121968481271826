import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Inject, InjectionToken, } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from '@root/app/components/home/home.component';
import { NavigationComponent } from '@root/app/components/navigation/navigation.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';

import { LoginComponent } from '@root/app/components/login/login.component';

import { TokenInterceptor } from '@services/auth/token-interceptor.service';
import { CustomTranslationProvider } from '@services/translate/translate.service';
import { L10nConfig, L10nLoader, TranslationModule, LocalizationModule, StorageStrategy, ProviderType, Language, L10N_CONFIG, L10nConfigRef } from 'angular-l10n';
import { MenuLeftTemplateComponent } from '@layouts/menu-left-template/menu-left-template.component';

import { LogoutComponent } from './components/logout/logout.component';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { ResetPasswordComponent } from './components/login/resetPassword/resetPassword.component';

import { SessionRestoreProvider, sessionRestoreProviderFactory } from '@root/app/service/session.service';
import { ChangePasswordComponent } from '@root/app/components/login/change-password/change-password.component';
import { SharedModule } from '@root/app/shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AppConfiguration } from './app-configuration.service';



const l10nConfig: L10nConfig = {
  locale: {

    languages: [
      { code: 'en', dir: 'ltr' },
      { code: 'it', dir: 'ltr' }
    ],
    defaultLocale: { languageCode: 'it', countryCode: 'IT' },
    storage: StorageStrategy.Session,
    timezone: 'Europe/Rome',

    // storage: StorageStrategy.Cookie
  },
  /*
  translation: {

    providers: [
      { type: ProviderType.Static, prefix: './assets/i18n/locale-'},

    ],
    caching: true,

    missingValue: 'No key',
    composedKeySeparator: '.',
  },
  */

  translation: {

    caching: false,
    missingValue: missingKey,
    providers: [
      {
        translationProvider: CustomTranslationProvider, storage: StorageStrategy.Session,
      }
    ],

    composedKeySeparator: '.',
  }


};

export function missingKey(path) {
  return '[' + path + '] is missing'
}

/**
 * Con il token APP_INITIALIZER posso inizializzare i provider
 * prima del caricamento dell'applicazione
 */
// Advanced initialization.
/*
export function initL10n(l10nLoader: L10nLoader): Function {
  return () => l10nLoader.load().then(
    data=>{
     // l10nConfig.locale.timezone = 'aa'
    }
  )
}*/

@NgModule({
  declarations: [
    AppComponent,

    HomeComponent,
    NavigationComponent,

    LoginComponent,

    MenuLeftTemplateComponent,

    LogoutComponent,
    // UrlNotFoundComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,


  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BreadcrumbModule,
    OverlayPanelModule,
    SidebarModule,
    LocalizationModule.forRoot(l10nConfig),
    TranslationModule.forRoot(
      l10nConfig,
      { translationProvider: CustomTranslationProvider },
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],


  providers: [
    SessionRestoreProvider,
    { provide: APP_INITIALIZER, useFactory: sessionRestoreProviderFactory, deps: [SessionRestoreProvider], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AppConfiguration,
    { provide: APP_INITIALIZER, useFactory: AppConfigurationFactory, deps: [AppConfiguration, HttpClient], multi: true},
    CustomTranslationProvider
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private l10nLoader: L10nLoader,
    private customTranslationProvider: CustomTranslationProvider,
    @Inject(L10N_CONFIG) private configuration: L10nConfigRef
  ) {
  }
}

export function AppConfigurationFactory(
  appConfig: AppConfiguration) {
  return () => appConfig.ensureInit();
}
