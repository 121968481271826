import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@root/app/app.component';

@Injectable({
  providedIn: 'root'
})

export class MenuService {

  constructor(private http:HttpClient) { }

  getMenu():Observable<any>{
    return this.http.get<any[]>(api+'bkouser/menu');
  }
}
