
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpClient, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { AuthService } from './auth.service';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { map, catchError, tap, switchMap, concatMap, mergeMap, flatMap, retry, share, take, finalize, filter } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { api, baseurl } from '@root/app/app.component'

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private excludeUrls: string[] = [
    'token/refresh',
    'login',
    'login/changepassword'
  ]



  constructor(public auth: AuthService, private route: ActivatedRoute, private router: Router, private http: HttpClient) { }

  isRefresh: boolean = false
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    });

    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            // Se non sono autorizzato, e non sono la pagina di refresh toke, intercetto e richiedon un nuovo token
            let shouldIntercept: boolean = this.excludeUrls.filter(x => {
              return api + x == request.url
            }).length == 0
            //Controllo se gestire l'errore
            if (shouldIntercept) {
              //Richiamo la funzione in base al codice di errore
              switch (error.status) {
                case 400: return this.handle400Error(error);
                case 401: return this.handle401Error(request, next);
              }
            }
          }
          return throwError(error);
        })
      );
  }

  private handle400Error(error: HttpErrorResponse) {
    if (error.error === 'Invalid refresh token') {
      this.unsetSession()
    }
    return throwError(error);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    //Controllo se devo effettuare la chiamata di refresh oppure è già in corso
    if (!this.isRefresh) {
      //Imposto il valore a true in modo da non eseguire un'altra volta la chiamata di refrsh
      this.isRefresh = true
      //Chiamata di refresh token
      return this.auth.refreshToken()
        .pipe(
          switchMap(
            () => {
              //Notifico alle altre chiamate la disponibilità del nuovo token
              //Effetto la chiamata con il nuovo token
              return next.handle(request.clone());
            }
          ),
          finalize(() => this.isRefresh = false),//Refresh terminato
          catchError(err => {
            //Chiamata non andata a buon fine, cancello la sessione e torno alla pagina di login
            this.unsetSession()
            return throwError(err);
          })
        )
    } else {
      return this.tokenSubject.pipe(
        //Controllo di sicurezza in moda evitare l'emissione di un altro valore che potrebbe annullare la richiesta
        take(1),
        //Eeseguo le chiamate con il token aggiornato
        switchMap(
          () => {
            return next.handle(request.clone());
          }
        )
      )
    }
  }

  private unsetSession() {
    localStorage.clear()
    this.router.navigate(['login'], { queryParams: { 'token': 'invalid' }, skipLocationChange: true })
  }
}