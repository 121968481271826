import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { tap, map, switchAll, switchMap } from 'rxjs/operators';
import { api } from '@root/app/app.component';

export interface LockUnlockInterface {
  tbName: string,
  pk: number
}

export interface LockUnlockResult {

  isEditable: boolean,
  isMyLock: boolean,
  usrIdLock: number,
  usrLoginLock: string
}

export class LockUnlockClass implements LockUnlockInterface {
  tbName = "";
  pk = null;
  constructor(tbName: string, pk: number) {
    this.tbName = tbName
    this.pk = pk
  }
}

@Injectable({
  providedIn: 'root'
})

export class LockService {

  constructor(
    private http: HttpClient
  ) { }

  // use this property for property binding
  public isLockTest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _isLockTest = false;

  public setLockTest(state: boolean) {
    this._isLockTest = state;
    this.isLockTest.next(state);
  }

  public setLockEntity(lock: LockUnlockClass): Observable<any> {
    return this.http.post(api + 'objectlock/lock', lock)
  }

  public setUnlockEntity(lock: LockUnlockClass): Observable<any> {
    return this.http.post(api + 'objectlock/unlock', lock)
  }

  public getLockCheckEntity(pk: number, tbName: string): Observable<LockUnlockResult> {
    let params = {
      pk: pk.toString(),
      tbName: tbName
    }
    return this.http.get<LockUnlockResult>(api + 'objectlock/check', { params: params })
  }
  //Funzione di verifica del lock e che può anche ottenerlo
  public getLockCheckAndGet(pk: number, tbName: string): Observable<any> {
    let params = {
      pk: pk.toString(),
      tbName: tbName
    }
    return this.http.get<any>(api + 'objectlock/checkandget', { params: params })
  }

}
