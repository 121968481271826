import { Component, OnInit } from '@angular/core';
import { CMSCONFIG } from '@app/globals'
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Language } from 'angular-l10n';
import { AuthService } from "@root/app/service/auth/auth.service";
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    private authService: AuthService
  ) { }

  display: boolean = false;
  displayChangePassword: boolean = false;
  @Language() lang: string;

  ngOnInit() {
    //Se il componente viene richiamato direttamente procedo con il logout diretto.
    if (this.router.url === '/logout') {
      this.setLogout();
    }
  }


  public showDialogLogout() {
    this.display = true;
  }

  public showDisplayChangePassword() {
    this.displayChangePassword = true;
  }

  public setLogout() {
    this.authService.logout().subscribe(
      () => {
        localStorage.clear();
        this.location.replaceState(CMSCONFIG.redirectToAfterLogout);
        this.router.navigate([CMSCONFIG.redirectToAfterLogout], { queryParams: { 'token': 'invalid' }, skipLocationChange: true });
      }
    );
  }

}
